<template>
  <CRow>
    <CCol md="9">
      <CCard>
        <CCardBody>
          <ValidationObserver ref="observer" @submit.prevent="updatePlatform">
            <CForm>
              <CInput :disabled="true" :label="$t('DETAIL_PLATFORM_PAGE_LABEL_PLATFORM')" horizontal :value="platform.name" />
              <CInput :disabled="true" :label="$t('DETAIL_PLATFORM_PAGE_LABEL_NAME')" horizontal :value="platform.name" />
              <CInput :disabled="true" :label="$t('DETAIL_PLATFORM_PAGE_LABEL_SYMBOL')" horizontal :value="platform.symbol" />
              <ValidationProvider
                rules="required|min_value:0|max_value:100"
                :name="$t('DETAIL_PLATFORM_PAGE_LABEL_EPY')"
                v-slot="{ errors }"
              >
                <div class="epy form-group form-row">
                  <label for class="col-form-label col-sm-3">{{$t("DETAIL_PLATFORM_PAGE_LABEL_EPY")}}:</label>

                  <div class="col-sm-9">
                    <currency-input
                      name="EPY"
                      class="form-control"
                      v-model="epy"
                      :currency="{ suffix: '%' }"
                      :precision="{minValue, maxValue}"
                      locale="en"
                    />
                    <p v-if="errors||isNegative" class="error-msg">{{errors[0]}}</p>
                  </div>
                </div>
              </ValidationProvider>

              <CRow>
                <CCol md="6">
                  <CRow>
                    <CCol sm="3" md="6">
                      <label>{{$t("DETAIL_PLATFORM_PAGE_LABEL_LOCKUP_UNVOTE")}}</label>
                    </CCol>
                    <CCol sm="9" md="6" class="lockup-unvote form-group">
                      <CInput :disabled="true" :value="platform.lockup_unvote" />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol md="6">
                  <CSelect
                    :label="$t('DETAIL_PLATFORM_PAGE_LABEL_UNIT')"
                    :disabled="true"
                    horizontal
                    :value="platform.lockup_unvote_unit"
                    :options="timeUnits"
                  />
                </CCol>
              </CRow>

              <CRow>
                <CCol md="3">
                  <h6 class="mt-1">{{$t("DETAIL_PLATFORM_PAGE_LABEL_STATUS")}}</h6>
                </CCol>
                <CCol md="9" class="status-select">
                  <select class="form-control" v-model="status">
                    <option
                      v-for="option in statusOptions"
                      v-bind:value="option.value"
                      :key="option.value"
                    >{{ option.name }}</option>
                  </select>
                </CCol>
              </CRow>
              <CRow class="mt-4 mb-2">
                <CCol md="3">
                  <h6>{{$t("DETAIL_PLATFORM_PAGE_LABEL_ICON")}}</h6>
                </CCol>
                <CCol md="9">
                  <div class="form-group file-area">
                    <label for="images"></label>
                    <input
                      @mouseover="toggleBtnUpload"
                      @mouseleave="toggleBtnUpload"
                      type="file"
                      name="images"
                      id="images"
                      @change="uploadImage"
                    />
                    <div class="file-dummy">
                      <img :src="previewImage?previewImage:platform.icon" />

                      <label :class="visibility?'':'hidden'">{{$t("DETAIL_PLATFORM_PAGE_LABEL_SELECT_IMAGE")}}</label>
                    </div>
                  </div>
                </CCol>
              </CRow>

              <CButton type="submit" color="primary" class="button-save">{{$t("DETAIL_PLATFORM_PAGE_BUTTON_SAVE")}}</CButton>
            </CForm>
          </ValidationObserver>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapState, mapAction, mapActions } from "vuex";
import endpoints from "@/constants/endpoints";
import platformStatuses from "@/constants/platformStatuses";
export default {
  data() {
    return {
      epy: null,
      icon: null,
      status: null,
      minValue: 0,
      maxValue: 20,
      visibility: false,
      previewImage: null,
      labelIcon: {
        labelOn: "\u2713",
        labelOff: "\u2715"
      },
      statusOptions: []
    };
  },
  computed: {
    ...mapState({
      platform: state => state.platform.selectedPlatform,
      timeUnits: state => state.platform.timeUnits
    })
  },
  mounted() {
    this.fetchTimeUnit();

    if (this.platform) {
      this.epy = Number(this.platform.estimate_earn_per_year);
      this.status = this.platform.status;
      this.statusOptions=[...platformStatuses]
    }
  },
  async beforeRouteEnter(to, from, next) {
    let platform = to.params.platform;
    if (!platform) {
      next("/admin/staking-platform");
    } else {
      next(vm => {
        if (vm.$store.state.platform.selectedPlatform.platform != platform) {
          next("/admin/staking-platform");
        } else {
          next();
        }
      });
    }
  },
  methods: {
    ...mapActions(["fetchTimeUnit", "updateStakingPlatform"]),
    uploadImage(event) {
      const image = event.target.files[0];
      this.icon = image;
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.previewImage = e.target.result;
      };
    },
    toggleBtnUpload() {
      this.visibility = !this.visibility;
    },

    
    async updatePlatform() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      const data = { ...this.platform };
      data.estimate_earn_per_year = this.epy;
      data.icon = this.icon;
      data.status = this.status;
      let result = await this.updateStakingPlatform(data);
      if (result) {
        this.$notify({
          group: "notifications",
          type: "success",
          title: this.$t('DETAIL_PLATFORM_PAGE_NOTIFY_SUCCESS'),
          text: this.$t('DETAIL_PLATFORM_PAGE_NOTIFY_SUCCESS_DESCRIPTION'),
          duration: 2000
        });
        this.$router.push("/admin/staking-platform");
      }
    }
  },
  components: {}
};
</script>

<style lang="scss">
.button-save {
  width: 150px;
  float: right;
}
.file-area {
  width: 100%;
  label {
    display: block;
  }
  input[type="file"] {
    position: absolute;
    width: 180px;
    height: 90%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 10;
  }
  input[type="file"]:hover {
    .select-upload {
      visibility: visible;
    }
  }

  .file-dummy {
    width: 160px;
    height: 160px;
    background: rgba(255, 255, 255, 0.2);
    transition: background 0.3s ease-in-out;
    position: relative;
    label {
      text-align: center;
      padding-top: 20px;
      cursor: pointer;
      position: absolute;
      top: 100px;
      text-align: center;
      width: 160px;
      height: 60px;
      background: rgba(0, 0, 0, 0.8);
      color: white;
      visibility: visible;
      transition: 0.3s;
    }
    .hidden {
      visibility: hidden;
    }
    img {
      width: 160px;
      height: 160px;
    }
  }

  input[type="file"]:valid + .file-dummy {
    background-color: #e5e5e5;
  }
}
.error-msg {
  color: red;
  font-size: 14px;
  line-height: 16px;
  padding-top: 5px;
}
.epy {
  margin-bottom: 0px;
}
.lockup-unvote {
  padding-left: 15px;
  @media (min-width: 576px) {
    padding-left: 10px;
  }
}
.status-select {
  padding-left: 15px;

  @media (min-width: 576px) {
    padding-left: 10px;
  }
}
</style>
